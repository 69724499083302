import React, { Component } from "react";
import { Input, Button, notification, Menu, Dropdown } from "antd";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	NavLink,
	Link,
	useRouteMatch,
	useParams,
	withRouter,
} from "react-router-dom";


const { SubMenu } = Menu;

class MenuListSecond extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuMode: null,
			loginVisible: false,
			signupVisible: false,
		};
	}

	loginPopup = () => {
		this.setState({ loginVisible: true });
	};

	signupPopup = () => {
		this.setState({ signupVisible: true });
	};

	closePopup = () => {
		this.setState({ loginVisible: false, signupVisible: false });
	};

	componentDidMount() {
		setTimeout(() => {
			this.updateMenuMode();
		}, 1000);

		window.addEventListener("resize", this.updateMenuMode()); // updateMenuMode arrow fun che so this thibindna karyu
	}

	updateMenuMode = () => {
		if (window.innerWidth < 768) {
			this.setState({ menuMode: "vertical" });
		} else {
			this.setState({ menuMode: "horizontal" });
		}
	};

	render() {
		if (this.state.menuMode) {
			const raceMenu = (
				<Menu>
					<Menu.Item>
						<a rel="noopener noreferrer" href="/edit-profile">
							Edit Profile
						</a>
					</Menu.Item>
					<Menu.Item>
						<a rel="noopener noreferrer" onClick={this.showPopup}>
							Change Password
						</a>
					</Menu.Item>
					<Menu.Item>
						<a rel="noopener noreferrer" href="/login">
							Logout
						</a>
					</Menu.Item>
				</Menu>
			);
			return (
				<div>
					<Menu mode={this.state.menuMode}>
						<Menu.Item>
							<Link className="remove-data-hover" data-hover="START INVESTING" to="/get-started">
								START INVESTING
							</Link>
						</Menu.Item>
						<Menu.Item>
							<Link data-hover="DASHBOARD" to="/dashboard">
								DASHBOARD
							</Link>
						</Menu.Item>
						<Menu.Item>
							<Link data-hover="EDIT MY CAMPAIGN" to="/edit-my-campaign">
								EDIT MY CAMPAIGN
							</Link>
						</Menu.Item>
						<SubMenu
							key="SubMenu"
							title={
								this.props.user && this.props.user.firstname
									? this.props.user.firstname
									: ""
							}>
							<Menu.Item key="setting:3">
								<Link to="/dashboard-overview">Overview</Link>
							</Menu.Item>
							<Menu.Item key="setting:3">
								<Link to="/edit-profile">Edit Profile</Link>
							</Menu.Item>
							<Menu.Item key="setting:4">
								<Link
									// onClick={() => {
									// 	this.props.history.push("/change-password");
									// }}
									to="/change-password">
									Change Password
								</Link>
							</Menu.Item>
							<Menu.Item key="setting:8">
								<Link
									onClick={() => {
										this.props.logout();
									}}
									to="/landing">
									Logout
								</Link>
							</Menu.Item>
						</SubMenu>

						{/* <Menu.Item className="overflow-visible">
                <div className="my-profile-menu">
                  <div id="profile_dd" className="default-dropdown top-arrow">
                    <Dropdown overlay={raceMenu} placement="bottomLeft" getPopupContainer={() => document.getElementById("profile_dd")}>
                      <Button className="race">Moonboys <i className="fa fa-caret-down" aria-hidden="true"></i></Button>
                    </Dropdown>
                  </div>
                </div>
              </Menu.Item> */}
					</Menu>
				</div >
			);
		} else {
			return "";
		}
	}
}


export default (MenuListSecond);
