import React, { Component, Suspense, lazy } from "react";
import AppRouter from "./routing";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  BrowserRouter,
} from "react-router-dom";
import Header from "./components/shared/header/header-main";
import Footer from "./components/shared/footer/footer";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>

        <Router>
          <Route
            render={({ location }) => (
              <Suspense className="loader" fallback={<div></div>}>
                {/* <Header /> */}
                <Switch location={location}>
                  <Route
                    path="/"
                    component={AppRouter}  // routing.js mathi ClassName Lidhu apde
                  />
                </Switch>
                <Footer />
              </Suspense>
            )}
          />
        </Router>

      </div>
    );
  }
}
export default App;
