import React, { Component } from "react";
import "antd/dist/antd.css";
import { Menu, Layout, Button, Drawer } from "antd";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	NavLink,
	Link,
	useRouteMatch,
	useParams,
	withRouter,
} from "react-router-dom";
import MenuListSecond from "./menu-second";

import { HeaderMain, TopHeader } from "./header-style";
import Progress from "./progressbar";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			current: "mail",
			visible: false,
			scrollY: 0,
			navbarColorOpacity: 0,
			isNavbarOnSticky: false,
		};
		this.handleScroll = this.handleScroll.bind(this);
	}

	showDrawer = () => {
		this.setState({
			visible: true,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	componentDidMount() {
		this.handleScroll();
		window.addEventListener("scroll", this.handleScroll);
	}

	handleScroll(event) {
		let navbarColorOpacity;
		let isNavbarOnSticky = false;
		if (window.scrollY > 80) {
			isNavbarOnSticky = true;
		}
		if (window.scrollY < 80) {
			navbarColorOpacity = 0;
		} else if (window.scrollY < 150) {
			navbarColorOpacity = 0.5;
		} else {
			navbarColorOpacity = 1;
		}
		this.setState({
			scrollY: window.scrollY,
			navbarColorOpacity,
			isNavbarOnSticky,
		});
	}

	render() {
		return (
			<HeaderMain>
				<div className="top-section bg-image full-width" id="home">
					<TopHeader
						style={{
							backgroundColor: `rgba(0, 0, 0, ${this.state.navbarColorOpacity})`,
						}}
						className={`${this.state.isNavbarOnSticky ? "fixed header-width" : "header-width"}`}>
						<div className="wrapper flex-center special-case">
							<div className="top-left">
								<Link to={`/dashboard`}>
									<img
										src=""
										alt="logo"></img>
								</Link>
							</div>
							<div className="top-right">
								<div className="leftMenu">
									<MenuListSecond />
								</div>
								<Button
									className="barsMenu"
									type="primary"
									onClick={this.showDrawer}>
									<i className="fa fa-bars" aria-hidden="true"></i>
								</Button>
								<Drawer
									title={
										<img
											src=""
											width="100%"
										/>
									}
									placement="right"
									closable={false}
									onClose={this.onClose}
									visible={this.state.visible}
									className="mobile-menu">
									<MenuListSecond />
									<Button className="close-menu" onClick={this.onClose}>
										<i className="fa fa-times" aria-hidden="true"></i>
									</Button>
								</Drawer>
							</div>
						</div>
					</TopHeader>
					<Progress />
				</div>
			</HeaderMain>
		);
	}
}



export default (Header);
