import React, { Component, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Redirect,
  Switch,
  browserHistory,
} from "react-router-dom";

const HomePage = lazy(() => import("./components/homepage/home"));


const routes = [
  {
    exact: true,
    path: "/",
    component: () => <HomePage />,
  },

];

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>

        <Suspense className="loader" fallback={<div></div>}>
          {/* <Switch> */}
          {routes.map((element) => (
            <Route
              exact={element.exact === false ? false : true}
              key={element.path}
              path={`${element.path}`}
              component={element.component}
              {...this.props}
            />
          ))}
          {/* </Switch> */}
        </Suspense>
      </div>
    );
  }
}

export default AppRouter;
