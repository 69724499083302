import React, { Component } from 'react';
import ProgressBar from "react-scroll-progress-bar";
import styled from "styled-components";


const ProgressMain = styled.div`
  > div { left: 0px; height: 3px; top: 83px !important;
    background-image: linear-gradient(-45deg, #fff,#fff );
    z-index:999 !important;
    border-radius:20px;
    background-size: 400% 400%;
    -webkit-animation: Gradient 15s ease infinite;
    -moz-animation: Gradient 15s ease infinite;
    animation: Gradient 10s ease infinite;
    @-webkit-keyframes Gradient { 	0% {background-position: 0% 50%; }	50% {background-position: 100% 50%; }	100% {	background-position: 0% 50%;	}}
    @-moz-keyframes Gradient {0% {	background-position: 0% 50%}50% {	background-position: 100% 50%; } 100% {		background-position: 0% 50%	}}
    @keyframes Gradient {	0% { background-position: 0% 50%	}	50% {		background-position: 100% 50%	}	100% {		background-position: 0% 50%	}}

     
 }

`;

export default class Progress extends React.Component {
    render() {
        return (
            <ProgressMain className="header-progress">
                <ProgressBar height="6" bgcolor="#000" duration="0.2" />
            </ProgressMain>
        );
    }
}