import styled from "styled-components";
import { fonts, theme, screen } from "../../../constants/constant";

export const FooterMain = styled.div`
// font-family: 'futuralightbt';
padding:70px 0 30px;
@media (max-width: 991px) {
  padding:55px 0 30px;
  @media only screen and (max-width:767px) {
    padding:40px 0 20px;
    br {
      display:none;
    }
  }
}
font-weight:300;
h4 {
  text-align:center;
  font-size:24px;
   @media only screen and (max-width:767px) {
     font-size:20px;
   }
  // font-family: 'futuralightbt';
}
 h3 {
   padding-bottom:15px;
    // font-family: 'futuralightbt';
    letter-spacing:4px;
    @media only screen and (max-width:767px) {
      padding-bottom:3px;
    }
  }
 .footer-copyright {
   padding-top:40px;
    @media only screen and (max-width:992px) {
      padding-top:30px;
      @media only screen and (max-width:767px) {
        padding-top:20px;
      }
    }
 }

`;

export const FormMain = styled.form`
margin-bottom:60px;

@media (max-width: 991px) {
  margin-bottom:50px;
  @media only screen and (max-width:767px) {
    margin-bottom:35px;
  }
}
    // font-family: 'futuralightbt';
    @media (min-width: 768px) { 
          .half-form-field {
              display: flex;
              justify-content: space-between;
              .form-field {
                  width:49% !important;
              }
              &.three-field {
                .form-field {
                  width: 32.3% !important;
                }
              }
            
    }
  }
    float:left;
    width:100%;
    .label {
      margin-bottom:5px;
      font-size:14px;
    }
    .full-width , .label , .ant-input { 
        float:left;
        color: #1d2a3b;
        width:100%;
    }
    .wrapper {     
        margin: 0 auto!important;
        width: 1170px;
        max-width: 100%;
        float: none!important; 
    }
    .form-field { margin:5px 0;
      position:relative;
      .default-dropdown {
        .ant-dropdown-trigger { 
          float:left; width:100%;     
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 5px;
          height: 40px;
          color: #1d2a3b;
          border: 1px solid #dadadb !important;
          padding: 4px 11px;
          background:#fff !important;
        }
      }
      textarea.ant-input { border:1px solid #D8D8D8; padding:10px; height:120px; 
        @media (max-width:767px) {
            height:70px; 
        }
      }
      .ant-input { 
        margin-top:0px; 
        height:40px;
        padding: 4px 11px;
        box-shadow: none !important;
        border: 1px solid rgba(13,33,48,1) !important;
        background:#fff !important;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #1d2a3b;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: #1d2a3b;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: #1d2a3b;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: #1d2a3b;
        }
        &.invalid {  }
      }
      .select-tag {
        position:relative;
        .ant-select-selection-item {
          color: #999999 !important;
          opacity: 1 !important;
          display: flex;
          align-items: center;
        }
         &.invalid { 
          .ant-select-selector { background:#ffcccc !important; } 
         }
        .ant-select-selector { 
          margin-top:5px; 
          height:40px;
          padding: 4px 11px;
          border: 1px solid #dadadb !important;
          float: left;
          width: 100%;
          color: #000000;
          font-size: 14px;
          font-family: "GraphikRegular";
          letter-spacing: 0.5px;
          cursor: pointer;
          box-shadow: none !important;
          &:focus {
            box-shadow: none;
          }
          &.invalid {
            border-bottom: 1px solid red;
            background:transparent !important;
          }
        }
         }
         .ant-select-arrow {
          &:after {
            top:50%;
            right:10px;
            transform:translateY(-50%);
          }
        }
      }
      .srv-validation-message { position:absolute; left:0; bottom:-30px; font-size:14px; margin:0 !important; text-align:left !important; }
      .submit-btn {
          padding: 0 20px !important;
          height: 40px !important;
          line-height: 35px !important;
          text-align: center;
          -webkit-transition: all 800ms ease;
          -moz-transition: all 800ms ease;
          -ms-transition: all 800ms ease;
          -o-transition: all 800ms ease;
          transition: all 800ms ease;
          background: rgba(13,33,48,1);
          border:0 !important;
          border:1px solid rgba(13,33,48,1) !important;
          box-shadow:none !important;
          margin-left:10px;
          position:relative;
          min-width: 130px;
          @media only screen and (max-width:767px) {
            min-width:initial;
          }
          border-radius:3px;
          &:hover {
            // background:transparent;
            background: rgba(143,221,223,1);
            span {
                color: rgba(13,33,48,1);
            }
          }
          span {
            line-height:normal;
            position:absolute;
            left:50%;
            top:50%;
            font-size:14px;
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            -o-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
          }
      }
    
`;
