import React, { Component } from "react";
import { Input, Button, Menu, Dropdown, Select, message } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import "antd/dist/antd.css";
import { FooterMain, FormMain } from "./footer_style";
import axios from 'axios';
import { globalVariables } from "../../../Globals";
import { Link } from "react-router-dom";

let { API_URL } = globalVariables;
const BaseURL = process.env.REACT_APP_API_PATH;
export default class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {},
			isSubmitLoading: false
		};
		this.validator = new SimpleReactValidator({
			autoForceUpdate: this,
			validators: {
				password: {
					message: "The password must match the required pattern.",
					rule: (val, params, validator) => {
						var patt = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%_])[A-Za-z\d!@#$%_]{8,}$/;
						return patt.test(val)
					}
				},
				matchPassword: {
					message: "Your entered passwords does not match",
					rule: (val, params, validator) => {
						if (this.state.fields.password == val) {
							return true
						} else {
							return false
						}
					}

				}
			}
		});
	}

	handleChange = (field, e) => {
		let fields = this.state.fields;

		if (e.target.value.trim() == "") {
			fields[field] = "";
		} else {
			fields[field] = e.target.value;
		}
		this.setState({ fields });
	};
	submit = (e) => {
		e.preventDefault()
		if (this.validator.allValid()) {
			this.setState({
				fields: {},
				errors: {},

			})
			// API Call
			axios.post(`${BaseURL}/subscriber/subscribe`, {
				email: this.state.fields["email"]
			}).then((response) => {
				if (response.data.status == 200) {
					message.success(response.data.message, 2)
					this.setState({
						fields: {},
						errors: {},
						isSubmitLoading: true
					})
				}
				else {
					message.error(response.data.message, 2)
				}
				this.setState({
					isSubmitLoading: false
				})
			}).catch((error) => {
				message.error("something went wrong.", 2)
				this.setState({
					isSubmitLoading: false
				})
			})
			// END API Call 
		}
		else {
			this.setState({
				errors: this.validator.getErrorMessages()
			})
			this.validator.showMessages();
		}
	}
	render() {
		const { fields } = this.state;
		return (
			<FooterMain className="common-padding footer-section full-width">
				<div className="wrapper">
					<h4>Subscribe to be the first to know about <br></br> our launch</h4>
					<FormMain onSubmit={this.submit}>
						<div className="full-width form-field">
							<div className="label full-width">
								Email*
							</div>
							<div className="flex-center full-width mobile-row">
								<Input
									type="email"
									value={fields["email"]}
									onChange={this.handleChange.bind(this, "email")}
									placeholder="Enter your email here*"
									pattern="/^[-a-zA-Z0-9~!$%^&*_=+}{\'?]+(\.[-a-zA-Z0-9~!$%^&*_=+}{\'?]+)*@([a-zA-Z0-9_][-a-zA-Z0-9_]*(\.[-a-zA-Z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|AERO|ARPA|BIZ|COM|COOP|EDU|GOV|INFO|INT|MIL|MUSEUM|NAME|NET|ORG|PRO|TRAVEL|MOBI|[a-zA-Z][a-zA-Z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/"
									className={this.state.errors.email ? "invalid" : ""}
								/>
								{this.validator.message(
									"email",
									fields["email"],
									"required|email"
								)}
								<Button type="primary" htmlType="submit" className="submit-btn" loading={this.state.isSubmitLoading}>
									<span className="mobile-hide">Subscribe Now</span>
									<span className="mobile-show"><i class="fa fa-paper-plane" aria-hidden="true"></i></span>
								</Button>
							</div>
						</div>

					</FormMain>
					<div className="full-width text-center">
						<h3>ABOUT MINTAGE</h3>
						We are THE social media platform for NFT's.<br></br> Our goal is to connect artists, collectors, sellers, and<br></br> cryptocurrency enthusiasts! Subscribe to receive updates<br></br> on when we launch, our new and exciting features, and<br></br> why we are passionate about NFT's.
					</div>
					<div className="text-center footer-copyright full-width">
						<div className="social-items">
							<a href="https://www.facebook.com/Mintage-Inc-111223661204099" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a>
							<a href="https://twitter.com/MintageI" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a>
							<a href="https://www.instagram.com/mintagemedia/?hl=en" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a>
						</div>
						© 2022 by Mintage Inc.
					</div>
				</div>
			</FooterMain>
		);
	}
}
